import React, {Component} from 'react';
import {LoginWrapper} from "../login";
import SHARED_ROUTES from "../../config/routes";
import {Link, Redirect, withRouter} from "react-router-dom";
import {Button} from "antd";
import {AuthRequest} from "../../../../api";
import API from "../../../../api/config";
import Loader from 'react-loader-spinner'
import {connect} from "react-redux";

class RegisterPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      isRegistered: false,
      errors: [],
    }
  }

  changeInput = e => {

    const {name, value} = e.target;

    this.setState({
      [name]: value,
    })
  }

  register = e => {
    e.preventDefault();

    const {first_name, last_name, email, password, passwordConfirm, isRegistering} = this.state;

    this.setState({
      errors: [],
    }, () => {
      if (password !== passwordConfirm) {
        const errors = this.state.errors.concat([
          'Passwords do not match',
        ]);
        this.setState({
          errors,
        })

        this.setState({
          passwordError: true,
        });
        return;
      }

      if (isRegistering) {
        return;
      }
      this.setState({
        isRegistering: true,
      })

      if (first_name === '' || last_name === '' || email === '' || password === '') {

        const errors = this.state.errors.concat([
          'You must fill in all fields',
        ]);
        this.setState({
          errors,
        })

        return;
      }


      const data = {
        first_name,
        last_name,
        email,
        password,
      }

      AuthRequest.post(API.REGISTER, data)
        .then(({data}) => {
          this.setState({
            isRegistering: false,
            isRegistered: true,
          })
        })
        .catch(err => {
          const {data} = err.response;
          const errors = [];

          for (const err in data.errors) {
            data.errors[err].forEach(e => {
              errors.push(e)
            })
          }

          this.setState({
            errors,
            isRegistering: false,
          })
        })
    })
  }

  render() {
    const {isLoggedIn} = this.props;
    let redirect = SHARED_ROUTES.LOGIN;

    if (this.props.location.state) {
      redirect = this.props.location.state.redirect || SHARED_ROUTES.LOGIN;
    }

    const {isRegistering, email, password, passwordConfirm, first_name, last_name, isRegistered, errors} = this.state;

    return (
      !isLoggedIn ?
        <LoginWrapper>
          <div className="alignContainer">
            <div className="loginWrapper">
              <h1 id="loginHeader">Create an Account</h1>
              {
                !isRegistered ?
                  (
                    !isRegistering
                      ?
                      <>
                        <div style={{
                          color: '#BB0000',
                          marginBottom: 15,
                        }}>
                          <ul style={{listStyle: 'none', padding: 0}}>
                            {
                              errors.map(e => {
                                return <li>{e}</li>;
                              })
                            }
                          </ul>
                        </div>

                        <form onSubmit={this.register}>
                          <div className="inputSection">
                            <label>FIRST NAME:</label>
                            <input key={'first_name'} value={first_name} name={'first_name'}
                                   onChange={this.changeInput}/>
                          </div>
                          <div className="inputSection">
                            <label>LAST NAME:</label>
                            <input key={'last_name'} value={last_name} name={'last_name'} onChange={this.changeInput}/>
                          </div>
                          <div className="inputSection">
                            <label>Email:</label>
                            <input key={'email'} value={email} name={'email'} onChange={this.changeInput}/>
                          </div>
                          <div className="inputSection">
                            <label>Password:</label>
                            <input type={'password'} key={'password'} value={password} name={'password'}
                                   onChange={this.changeInput}/>
                          </div>
                          <div className="inputSection">
                            <label>Confirm Password:</label>
                            <input type={'password'} key={'passwordConfirm'} value={passwordConfirm}
                                   name={'passwordConfirm'}
                                   onChange={this.changeInput}/>
                          </div>
                          <div className="inputSection btnSection">
                            <Button loading={isRegistering} htmlType={'submit'} className={'authButton'}>Register</Button>
                          </div>
                        </form>
                        <div className={'loginLinks'}>
                          <a onClick={() => this.setState({currentPage: 'reset'})}>Forgot your password?</a>
                        </div>
                        <div className={'loginLinks'}>
                          <Link to={SHARED_ROUTES.LOGIN}>Register</Link>
                        </div>
                      </>
                      :
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        <Loader
                          type={"TailSpin"}
                          width={50}
                          height={50}
                        />
                      </div>
                  )
                  :
                  <>
                    Successfully completed registration. <Link to={redirect}>Go back</Link>
                  </>
              }
            </div>
          </div>
        </LoginWrapper>
        : <Redirect to={redirect}/>
    );
  }
}


const mapStateToProps = state => ({
  isLoggedIn: state.Auth.get('accessToken') !== null
});

export default withRouter(connect(mapStateToProps)(RegisterPage));
