const layoutActions = {
    OPEN_MOBILE_NAV: 'OPEN_MOBILE_NAV',
    CLOSE_MOBILE_NAV: 'CLOSE_MOBILE_NAV',

    openMobileNav: () => ({
        type: layoutActions.OPEN_MOBILE_NAV
    }),

    closeMobileNav: () => ({
        type: layoutActions.CLOSE_MOBILE_NAV,
    })
}

export default layoutActions;