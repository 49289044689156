import {Map} from "immutable";
import {AuthRequest} from "../../../api";

export function getToken(){
    try{
        const access_token = localStorage.getItem('accessToken');

        AuthRequest.defaults.headers.Authorization = 'Bearer ' + access_token;
        return new Map({accessToken: access_token});
    }
    catch(err){
        clearToken();
        return new Map();
    }
}

export function clearToken(){
    localStorage.removeItem('accessToken');
}
