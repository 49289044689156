import jwt_decode from 'jwt-decode';
import {dispatch} from 'redux';
import {AuthRequest} from "./index";
import API from "./config";

export const checkAuth = store => next => action =>{
    const token = localStorage.getItem("accessToken");

    if(token == null){
        // localStorage.clear();
        next(action);
        return;
    }

    if (jwt_decode(token).exp < Date.now() / 1000) {
        next(action);
        // localStorage.clear();
    }
    next(action);
}