import {takeEvery, all, put, fork} from 'redux-saga/effects';
import {clearToken} from "../../lib/shared/utilities/auth";
import {push} from 'connected-react-router';
import authActions from "./actions";
import SHARED_ROUTES from "../../lib/shared/config/routes";
import {AuthRequest} from "../../api";
import API from "../../api/config";

export function* loginRequest(){
}

export function* loginSuccess(){
    yield takeEvery(authActions.ASSIGN_TOKEN, function*({accessToken, user}){
        localStorage.setItem('accessToken', accessToken);

        AuthRequest.defaults.headers.Authorization = 'Bearer ' + accessToken;


        let firstTimeVisit = false;
        let survey = null;

        if(!localStorage.getItem('visitCount')){
            firstTimeVisit = true
        }
        else{
            let count = parseInt(localStorage.getItem('visitCount'))
            count += 1;

            localStorage.setItem('visitCount', count)
        }

        const loginData = {
            type: authActions.LOGIN_SUCCESS,
            token: accessToken,
            user: user,
            firstTimeVisit: firstTimeVisit,
            survey: survey,
        }


        yield put(loginData);
    });
}

export function* loginFailure(){

}

export function* logout() {
    yield takeEvery(authActions.LOGOUT, function*({redirect}) {
        yield AuthRequest.get(API.LOGOUT)
          .then(() => {
          })
          .catch(() => {
              console.log('something went wrong');
          })

        // clear beacon
        window.Beacon('logout');

        clearToken();
        if(redirect){
            console.log(redirect)
            if(typeof redirect === 'boolean') {
                yield put(push(SHARED_ROUTES.LOGIN));
            }
            else {
                yield put(push(redirect));
            }
        }
    });
}

export default function* rootSaga(){
    yield all([
        fork(loginRequest),
        fork(loginFailure),
        fork(loginSuccess),
        fork(logout),
    ])
}
