import React, {Component} from 'react';
import {LoginWrapper} from "../login";
import SHARED_ROUTES from "../../config/routes";
import {Link, Redirect, withRouter} from "react-router-dom";
import {Button} from "antd";
import {AuthRequest} from "../../../../api";
import API from "../../../../api/config";
import Loader from 'react-loader-spinner'
import {connect} from "react-redux";
import site_config from "../../../../config";

class ResetPasswordPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      isResetPassword: false,
      errors: [],
    }
  }

  changeInput = e => {

    const {name, value} = e.target;

    this.setState({
      [name]: value,
    })
  }

  register = e => {
    e.preventDefault();

    const {first_name, last_name, email, password, passwordConfirm, isResetting} = this.state;
    const {token} = this.props.match.params;
    console.log(token);

    this.setState({
      errors: [],
    }, () => {
      if (password !== passwordConfirm) {
        const errors = this.state.errors.concat([
          'Passwords do not match',
        ]);
        this.setState({
          errors,
        })

        this.setState({
          passwordError: true,
        });
        return;
      }

      if (isResetting) {
        return;
      }
      this.setState({
        isResetting: true,
      })

      if (password === '') {

        const errors = this.state.errors.concat([
          'You must enter a password',
        ]);
        this.setState({
          errors,
        })

        return;
      }


      const data = {
        token,
        password,
      }

      AuthRequest.post(API.RESET_PASSWORD, data)
        .then(({data}) => {

          if(data.success === true){
            this.setState({
              isResetPassword: true,
            })
          }
          else{
            const errors = [`The password reset link expired. If you are having trouble resetting your password, send an email to ${site_config.support_email}`];
            this.setState({
              errors
            })
          }
        })
        .catch(err => {
          const errors = [`The password reset link expired. If you are having trouble resetting your password, send an email to ${site_config.support_email}`];

          this.setState({
            errors,
          })
        })
        .finally(() => {
          this.setState({
            isResetting: false,
          })
        })
    })
  }

  render() {
    const {isLoggedIn} = this.props;

    const {isResetting, password, passwordConfirm, isResetPassword, errors} = this.state;

    return (
      <LoginWrapper>
        <div className="alignContainer">
          <div className="loginWrapper">
            <h1 id="loginHeader">Reset Password</h1>
            {
              !isResetPassword ?
                (
                  !isResetting
                    ?
                    <>
                      <div style={{
                        color: '#BB0000',
                        marginBottom: 15,
                      }}>
                        <ul style={{listStyle: 'none', padding: 0}}>
                          {
                            errors.map(e => {
                              return <li>{e}</li>;
                            })
                          }
                        </ul>
                      </div>

                      <form onSubmit={this.register}>
                        <div className="inputSection">
                          <label>Password:</label>
                          <input type={'password'} key={'password'} value={password} name={'password'}
                                 onChange={this.changeInput}/>
                        </div>
                        <div className="inputSection">
                          <label>Confirm Password:</label>
                          <input type={'password'} key={'passwordConfirm'} value={passwordConfirm}
                                 name={'passwordConfirm'}
                                 onChange={this.changeInput}/>
                        </div>
                        <div className="inputSection btnSection">
                          <Button loading={isResetting} htmlType={'submit'} className={'authButton'}>Reset
                            Password</Button>
                        </div>
                      </form>
                      <div className={'loginLinks'}>
                        <a onClick={() => this.setState({currentPage: 'reset'})}>Log In</a>
                      </div>
                    </>
                    :
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                      <Loader
                        type={"TailSpin"}
                        width={50}
                        height={50}
                      />
                    </div>
                )
                :
                <>
                  Successfully reset password. <Link to={SHARED_ROUTES.LOGIN}>Log In</Link>
                </>
            }
          </div>
        </div>
      </LoginWrapper>
    );
  }
}


const mapStateToProps = state => ({
  isLoggedIn: state.Auth.get('accessToken') !== null
});

export default withRouter(connect(mapStateToProps)(ResetPasswordPage));
