const TRUST_ROUTES = {
    INDEX: '/trust',
    NO_ACCESS: '/trust/no_access',

    PROPERTY_LIST: '/trust/properties/',
    PROPERTY_ALL: '/trust/properties/all',
    PROPERTY_VIEW: '/trust/properties/view/:id',
    PROPERTY_VIEW_REQUEST: '/trust/properties/view/:id/request',
    PROPERTY_RESERVE: '/trust/reserve/:availability',
}

export default TRUST_ROUTES;
