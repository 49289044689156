const WindowActions = {
    ONRESIZE: 'ONRESIZE',
    RESIZE: 'RESIZE',

    resize: data => {
        return {
            type: WindowActions.RESIZE,
            data: {
                innerWidth: data.innerWidth,
                innerHeight: data.innerHeight,
                mobile: data.mobile,
                tablet: data.tablet,
                desktop: data.desktop,
            }
        }
    }
}

export default WindowActions;