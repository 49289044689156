import React, {Component} from 'react';
import NProgress from 'nprogress';
import {Skeleton} from "antd";
import '../styles/nprogress.scss';

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
      NProgress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const {default: Component} = await importComponent();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />
        }, () => {
          NProgress.done();
        });
      }

    }

    render() {
      const Component = this.state.component || <div/>;
      return (
         <>
           {Component}
         </>
      );
    }
  }

  return AsyncFunc;
}
