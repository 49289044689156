import React from 'react';
import './App.scss';
import AppRouter from './lib/shared/router/AppRouter';
import ResponsiveProvider from "./lib/shared/ResponsiveProvider";
import UserInfoUpdater from "./lib/shared/UserInfoUpdater";

function App(props) {
    const {history} = props;

  return (
      <>

              <UserInfoUpdater>
                  <ResponsiveProvider>
                      <AppRouter history={history}/>
                  </ResponsiveProvider>
              </UserInfoUpdater>

      </>
  );
}

export default App;
