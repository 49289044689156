import React, {Component} from 'react';
import {AuthRequest} from "../../api";
import API from "../../api/config";
import {connect} from 'react-redux';
import authActions from "../../redux/auth/actions";
import {clearToken} from "./utilities/auth";
const {updateUser, logout} = authActions;

class UserInfoUpdater extends Component {

    componentDidMount() {
      this.updateUser();
      setInterval(this.updateUser, 1000 * 60 * 5); // every 5 minutes
    }

    updateUser = () => {
      if(this.props.accessToken != null){
        AuthRequest.get(API.CURRENT_USER)
          .then(({data: {user, reservations_count, watchlist_count}}) => {
            const userData = {
              ...user,
              reservations_count,
              watchlist_count,
            }

            this.props.updateUser(userData);
          })
          .catch(() => {
            this.props.logout();
          })
      }
    }

    render() {
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    accessToken: state.Auth.get('accessToken')
})
export default connect(mapStateToProps, {updateUser, logout})(UserInfoUpdater);
