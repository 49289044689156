const COURSE_ROUTES = {
    INDEX: '/leisure-school',

    PURCHASE_COURSE: '/enroll',
    PURCHASE_COURSE_PROMO: '/enroll/promo',

    COURSE_VIEW: '/leisure-school',
    COURSE_VIEW_GETTING_STARTED: '/leisure-school/getting-started',

    COURSE_VIEW_RESOURCES: '/leisure-school/resources',
    COURSE_VIEW_RESOURCES_GROUP: '/leisure-school/resources/:resourceGroupId',
    // COURSE_VIEW_RESOURCES_ITEM: '/leisure-school/resources/:resourceGroupId/item/:resourceId',
    RESOURCE_SEARCH: '/leisure-school/resource-search/:searchString',

    COURSE_VIEW_ANSWER_VAULT: '/leisure-school/answer-vault',
    COURSE_VIEW_ANSWER_VAULT_MODULE: '/leisure-school/answer-vault/module/:moduleId',
    COURSE_VIEW_ANSWER_VAULT_ANSWER: '/leisure-school/answer-vault/answer/:questionId',
    ANSWER_VAULT_SEARCH: '/leisure-school/answer-vault-search/:searchString',

    COURSE_VIEW_SCHEDULE: '/leisure-school/schedule',
    COURSE_SUBMIT_TESTIMONIALS: '/leisure-school/submit-testimonial',
    COURSE_REMINDERS: '/leisure-school/reminders',
    COURSE_REMINDERS_CREATE: '/leisure-school/reminders/create',

    COURSE_VIEW_LIVESESSIONS: '/leisure-school/office-hours',

    COURSE_VIEW_OFFICE_HOURS: '/leisure-school/office-hours',
    COURSE_VIEW_OFFICE_HOURS_VIEW: '/leisure-school/office-hours/:officeHourId',

    COURSE_VIEW_COMMUNITY: '/leisure-school/community',
    COURSE_VIEW_MODULE: '/leisure-school/modules/:moduleSlug',

    COURSE_VIEW_LESSON: '/leisure-school/lessons/:lessonSlug',

    SUPPORT: '/leisure-school/support',
    VIEW_MEMBERS: '/leisure-school/members',

    FOOTER_TERMS: '/leisure-school/terms',
    FOOTER_PRIVACY: '/leisure-school/privacy-policy',
    FOOTER_COOKIE_POLICY: '/leisure-school/cookie-policy',


}

export default COURSE_ROUTES;
