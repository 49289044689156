import {takeEvery, all, put, fork} from 'redux-saga/effects';
import {clearToken} from "../../lib/shared/utilities/auth";
import {push} from 'connected-react-router';
import WindowActions from "./actions";
import SHARED_ROUTES from "../../lib/shared/config/routes";

export function* onResize(action){
    // yield takeEvery(WindowActions.ONRESIZE, function (action) {
    //
    //     const data = {
    //         type: WindowActions.RESIZE,
    //         data: action.data
    //     };
    //     console.log(data)
    //     put(data);
    // });
}

export default function* WindowSagas(){
    yield all([
        fork(onResize)
    ])
}