const authActions = {

    LOGIN_REQUEST: 'LOGIN_REPORT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    ASSIGN_TOKEN: 'ASSIGN_TOKEN',
    UPDATE_USER: 'UPDATE_USER',
    LOGOUT: 'LOGOUT',
    SET_COURSE_ACCESS: 'SET_COURSE_ACCESS',
    REMOVE_FIRST_TIME_OVERLAY: 'REMOVE_FIRST_TIME_OVERLAY',
    SET_SURVEY: 'SET_SURVEY',

    setSurvey: survey => ({
        type: authActions.SET_SURVEY,
        survey,
    }),

    logout: (redirect = true) => ({
        type: authActions.LOGOUT,
        redirect,
    }),

    login: (email, password) => ({

    }),

    removeFirstTime: (email, password) => ({
        type: authActions.REMOVE_FIRST_TIME_OVERLAY
    }),

    forceLogin: (accessToken, user) => ({
        type: authActions.ASSIGN_TOKEN,
        accessToken: accessToken,
        user: user,
    }),

    updateUser: user => ({
        type: authActions.UPDATE_USER,
        user: user
    }),

    setCourseAccess: access => ({
        type: authActions.SET_COURSE_ACCESS,
        access,
    })
};

export default authActions;
