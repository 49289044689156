import React from 'react';
import {Redirect} from "react-router-dom";
import SHARED_ROUTES from "../../config/routes";
import TRUST_ROUTES from "../../../trust/config/routes";

const ApplicationIndex = () => {
    return (
        <Redirect to={SHARED_ROUTES.LOGIN_REDIRECT}/>
    );
};

export default ApplicationIndex;
