const courseNavActions = {
    SET_CURRENT_ITEM: 'SET_CURRENT_ITEM',
    OPEN_SEARCH: 'OPEN_SEARCH',
    CLOSE_SEARCH: 'CLOSE_SEARCH',
    OPEN_COURSE_MOBILE_NAV: 'OPEN_COURSE_MOBILE_NAV',
    CLOSE_COURSE_MOBILE_NAV: 'CLOSE_COURSE_MOBILE_NAV',
    SET_MODULES: 'SET_MODULES',
    OPEN_MEMBER: 'OPEN_MEMBER',
    CLOSE_MEMBER: 'CLOSE_MEMBER',
    UPDATE_MEMBERS: 'UPDATE_MEMBERS',
    CLEAR_MEMBERS: 'CLEAR_MEMBERS',
    START_TOUR: 'START_TOUR',
    END_TOUR: 'END_TOUR',
    LIVE_SESSION_RUNNING: 'LIVE_SESSION_RUNNING',
    LIVE_SESSION_NOT_RUNNING: 'LIVE_SESSION_NOT_RUNNING',
    ENROLL_PROMPT: 'ENROLL_PROMPT',
    SET_LESSONS: 'SET_LESSONS',

    setCurrentNavItem: currentItem => ({
        type: courseNavActions.SET_CURRENT_ITEM,
        current_item: currentItem,
    }),

    openSearch: () => ({
        type: courseNavActions.OPEN_SEARCH,
    }),
    closeSearch: () => ({
        type: courseNavActions.CLOSE_SEARCH,
    }),

    openMobileNav: () => {
        return {
            type: courseNavActions.OPEN_COURSE_MOBILE_NAV,
        }
    },
    closeMobileNav: () => {
        return {type: courseNavActions.CLOSE_COURSE_MOBILE_NAV,}
    },

    setModules: (modules) => ({
        type: courseNavActions.SET_MODULES,
        modules,
    }),

    setLessons: (lessons) => ({
        type: courseNavActions.SET_LESSONS,
        lessons,
    }),

    openMember: (user) => ({
        type: courseNavActions.OPEN_MEMBER,
        user
    }),

    closeMember: () => ({
        type: courseNavActions.CLOSE_MEMBER,
    }),

    updateMembers: (members, currentPage) => ({
        type: courseNavActions.UPDATE_MEMBERS,
        currentPage,
        members,
    }),

    clearMembers: (members) => ({
        type: courseNavActions.CLEAR_MEMBERS,
        members,
    }),

    startTour: () => ({
        type: courseNavActions.START_TOUR,
    }),

    endTour: () => ({
        type: courseNavActions.END_TOUR
    }),

    setLiveSessionRunning: () => ({
        type: courseNavActions.LIVE_SESSION_RUNNING
    }),

    setLiveSessionNotRunning: () => ({
        type: courseNavActions.LIVE_SESSION_NOT_RUNNING
    }),

    enrollPrompt: show => ({
        type: courseNavActions.ENROLL_PROMPT,
        show,
    })

}

export default courseNavActions;
