import {Map} from 'immutable';
import layoutActions from "./layoutActions";
const initState = new Map({
   mobileNavOpen: false,
});


export default function layoutReducer(state = initState, action){
    switch(action.type){
        case layoutActions.OPEN_MOBILE_NAV:
            return state.set('mobileNavOpen', true);

        case layoutActions.CLOSE_MOBILE_NAV:
            return state.set('mobileNavOpen', false);

        default:
            return state;
    }
}