const site_config = {
    colors: {
        text_muted: '#999',
        light_grey: '#DDD',
        text: '#333',
        hover: '#666',
    },

    fontSize: {
        normal: '14px',
        small: '13px;',
        big: '16px',
        medium: '18px',
        large: '24px',
        extraLarge: '36px',
        huge: '32px',
    },

    pagination: {
        properties: 9,
    },

    support_email: 'support@leisureschool.com',


    API: {
        googleMaps: 'AIzaSyDXSrzTISoC6LYxwKYOToWV1WIlFDTcgkg',
        stripe_public_key: 'pk_live_rmqxGKKUlqYA6F11hBiwpdwK008YshGIu6', // Real Key
        // stripe_public_key: 'pk_test_G4TXwdTEfyL9szTYEwpoXGwJ00k8tsdefu', // Test Key
        beaconId: '4ef14a19-379d-4c16-9e16-bdf0dcc6301e',
        beaconSignature: 'hXMvOOAfFlgYGoGe9oZCDRjNr2rqf6/ADrF1q/sOnuo=',
    }
}

export default site_config;
