import React, {Component} from 'react';
import {Route, Redirect} from "react-router-dom";
import {ConnectedRouter} from 'connected-react-router';
import asyncComponent from "../utilities/asyncComponent";
import {connect} from "react-redux";
import SHARED_ROUTES from "../config/routes";
import {history} from "../../../redux/store";
import TRUST_ROUTES from "../../trust/config/routes";
import ApplicationIndex from "../pages/index/ApplicationIndex";
import COURSE_ROUTES from "../../course/config/routes";
import LoginPage from '../../shared/pages/login';
import RegisterPage from "../pages/register";
import ResetPasswordPage from "../pages/reset";

const LoginComponent = asyncComponent(() => import('../pages/login'));

const TrustRoute = ({isLoggedIn, ...rest}) => {
  const TrustComponent = asyncComponent(() => import('../../trust/containers/TrustContainer/index'));
  return (
     <Route
        {...rest}
        path={TRUST_ROUTES.INDEX}

        render={props => <TrustComponent {...props} isLoggedIn={isLoggedIn}/>}

        // render={props => isLoggedIn ?
        //    <TrustComponent {...props}/> :
        //    <LoginComponent {...props}/>}
     />
  );
}

const CoursesRoute = (rest) => {
  const CourseComponent = asyncComponent(() => import('../../course/containers/CourseAuth'));

  return (
     <Route
        {...rest}
        path={COURSE_ROUTES.INDEX}
        render={props => <CourseComponent {...props}/>}
     />
  );
}

const UserProfileRoute = ({isLoggedIn}, ...rest) => {
  const ProfileComponent = asyncComponent(() => import('../pages/userprofile/index'));
  return (
     <Route
        {...rest}
        path={'/profile'}
        render={props => isLoggedIn ? <ProfileComponent {...props}/> :
           <Redirect to={{pathname: "/login", state: {prevPath: history.location.pathname}}}/>}
     />
  );
}

const LoginRoute = ({isLoggedIn}, ...rest) => {
  const LoginComponent = asyncComponent(() => import('../pages/login'));

  const redirectRoute = localStorage.getItem('loginRedirect') ? localStorage.getItem('loginRedirect') : SHARED_ROUTES.LOGIN_REDIRECT;

  return (
     <>
       <Route
          {...rest}
          path={SHARED_ROUTES.LOGIN}
          // render={props => !isLoggedIn ? <LoginComponent {...props}/> : <Redirect to={redirectRoute}/>}
          component={LoginPage}
       />
       <Route
          {...rest}
          path={SHARED_ROUTES.REGISTER}
          // render={props => !isLoggedIn ? <LoginComponent {...props}/> : <Redirect to={redirectRoute}/>}
          component={RegisterPage}
       />
       <Route
          {...rest}
          path={SHARED_ROUTES.RESET_PASSWORD}
          // render={props => !isLoggedIn ? <LoginComponent {...props}/> : <Redirect to={redirectRoute}/>}
          component={ResetPasswordPage}
       />
     </>
  )
}


class LoggedInRoutes extends Component {
  render() {
    const {
      isLoggedIn
    } = this.props;

    return <>
      <UserProfileRoute isLoggedIn={isLoggedIn} history={history}/>
      <LoginRoute isLoggedIn={isLoggedIn} history={history}/>
      <TrustRoute isLoggedIn={isLoggedIn} history={history}/>
    </>
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.Auth.get('accessToken') !== null
  }
}

const LoggedInRouter = connect(mapStateToProps)(LoggedInRoutes)

class AppRouter extends Component {
  render() {
    const {
      history,
    } = this.props;

    return (
       <ConnectedRouter history={history}>
         <LoggedInRouter/>
         <CoursesRoute/>
         <Route exact path={SHARED_ROUTES.INDEX} component={ApplicationIndex}/>
         <Route exact path={TRUST_ROUTES.NO_ACCESS}
                component={asyncComponent(() => import('../../trust/pages/noaccess'))}/>
         <Route exact path={COURSE_ROUTES.PURCHASE_COURSE}
                component={asyncComponent(() => import('../../course/pages/course/PurchaseNew'))}/>
         <Route exact path={COURSE_ROUTES.PURCHASE_COURSE_PROMO}
                component={asyncComponent(() => import('../../course/pages/course/PurchasePromo'))}/>
         {/*<Route exact path={COURSE_ROUTES.PURCHASE_COURSE_NEW}*/}
         {/*       component={asyncComponent(() => import('../../course/pages/course/PurchaseNew'))}/>*/}
       </ConnectedRouter>
    );
  }
}

export default AppRouter;
