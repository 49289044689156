const API = {
    BASE_URL: 'https://api.leisureschool.com/api',
    // BASE_URL: 'http://localhost:8000/api',

    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER: '/register',
    RESET_PASSWORD: '/reset',
    RESET_PASSWORD_TOKEN: '/reset_link',
    CURRENT_USER: '/user',
    CURRENT_USER_RESERVATIONS: '/user/reservations',
    CURRENT_USER_WATCHLIST: '/user/watchlist',
    CURRENT_USER_COURSES: '/user/courses',
    CURRENT_USER_REQUEST_TRUST_ACCESS: '/user/request_trust_access',
    CURRENT_USER_UPLOAD_IMAGE: '/user/picture',
    CURRENT_USER_UPDATE: '/user',

    LOCATIONS_GET_ALL: '/locations/all',
    LOCATIONS_GET_ALL_GROUP: '/locations/all/group',
    LOCATIONS_GET_AVAILABLE: '/locations/available',
    LOCATIONS_SEARCH: '/locations/search',


    PROPERTIES_AVAILABLE: '/properties/availabilities',
    PROPERTIES_ALL: '/properties',
    PROPERTIES_VIEW: '/properties/:id',
    PROPERTY_WATCH: '/properties/:id/watch',
    PROPERTY_UNWATCH: '/properties/:id/unwatch',

    PROPERTY_RESERVE: '/availabilities/reserve',
    PROPERTY_REQUEST_WEEK: '/availabilities/request',

    AVAILABILITY_VIEW: '/availabilities/:id',
    // CURRENT_USER_RESERVATIONS: '/user/reservations',


    //////////////////////////
    // COURSE
    //////////////////////////

    COURSE_CHECK_ACCESS: '/courses/checkAccess',

    PURCHASE_COURSE: '/courses/purchase',
    CHECK_COUPON: '/courses/check_coupon/:couponCode',
    TIMESHARE_COMPANIES: '/courses/timeshares',

    COURSE_GET_MODULES: '/courses/modules',
    COURSE_GET_MODULE: '/courses/modules/:moduleSlug',
    COURSE_GET_MODULES_PUBLIC: '/public/courses/modules',

    COURSE_GET_LESSONS: '/courses/lessons',
    COURSE_GET_LESSONS_PUBLIC: '/public/courses/lessons',
    COURSE_GET_LESSON: '/courses/lessons/:lessonSlug',
    COURSE_GET_LESSON_ANSWERS: '/courses/lessons/:lessonSlug/answers',
    COURSE_GET_LESSON_SURVEY: '/courses/surveys/lesson/:lessonSlug',

    COURSE_POST_COMMENT: '/courses/comments',
    COURSE_GET_COMMENTS: '/courses/comments/lesson/:lessonId',
    COURSE_GET_COMMENT: '/courses/comments/comment/:commentId',

    COURSE_GET_MEMBERS: '/courses/members',
    COURSE_GET_MEMBER: '/courses/members',

    COURSE_LESSON_MARK_COMPLETE: '/courses/lessons/:lessonSlug/markComplete',
    COURSE_LESSON_MARK_INCOMPLETE: '/courses/lessons/:lessonSlug/markIncomplete',

    COURSE_GET_ANSWER_MODULE: '/courses/answers/module/:moduleSlug',
    COURSE_GET_ANSWER: '/courses/answers/answer/:answerId',
    COURSE_GET_ANSWER_SEARCH: '/courses/answers/search',

    COURSE_GET_RESOURCES: '/courses/resources',
    COURSE_GET_RESOURCES_BY_LESSON: '/courses/resources/lesson/:lessonSlug',
    COURSE_GET_RESOURCE_LINKS: '/courses/resources',

    LIVESESSION_GET_CURRENT: '/courses/livesessions/live',
    LIVESESSION_GET_PAST: '/courses/livesessions/past',

    CALENDAR_GET_EVENTS: '/courses/schedules',
    CALENDAR_GET_OFFICE_HOURS: '/courses/schedules/office_hours',


    COURSE_SUBMIT_SURVEY: '/courses/surveys/submit/lesson',
    LIVESESSION_SUBMIT_SURVEY: '/courses/surveys/submit/livesession',

    COURSE_GET_USABILITY_FEEDBACK_SURVEY: '/courses/surveys/usability-feedback',
    COURSE_SUBMIT_USABILITY_FEEDBACK_SURVEY: '/courses/surveys/usability-feedback',

    UPDATE_COURSE_LESSON_TRACKER: '/courses/tracker/lessons',
    UPDATE_COURSE_LIVE_SESSION_TRACKER: '/courses/tracker/livesessions',

    REMINDER_CREATE: '/courses/reminders',
    REMINDER_GET: '/courses/reminders',

    COURSE_SUBMIT_TESTIMONIAL: '/courses/testimonials',

    // few admin functions
    COURSE_ADMIN_DELETE_COMMENT: '/admin/courses/comments/:commentId/delete',
}

export default API;
