import {Map} from "immutable";
import actions from './actions';

const initState = new Map({
    innerWidth: 0,
    innerHeight: 0,
    mobile: false,
    tablet: false,
    desktop: false,
});

export default function windowReducer(
    state = initState,
    action
){
    switch (action.type) {
        case actions.RESIZE:
            return state.set('innerWidth', action.data.innerWidth)
                .set('innerHeight', action.data.innerHeight)
                .set('desktop', action.data.desktop)
                .set('tablet', action.data.tablet)
                .set('mobile', action.data.mobile);

        default:
            return state;
    }
}