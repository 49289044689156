import React, {Component} from 'react';
import LoginBg from '../../assets/loginbg.jpg';
import styled from "styled-components";
import site_config from "../../../../config";
import {Switch, Route, Link, Redirect} from "react-router-dom";
import SHARED_ROUTES from "../../config/routes";
import {Button} from "antd";
import {connect} from "react-redux";
import authActions from "../../../../redux/auth/actions";
import BasicRequest from "../../../../api";
import API from "../../../../api/config";
import COURSE_ROUTES from "../../../course/config/routes";

const {forceLogin} = authActions;

export const LoginWrapper = styled.div`
    background-image: url(${LoginBg});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    
    .alignContainer{
        width: 325px;
        position: relative;
        z-index: 2;
        
        .loginWrapper{
            padding: 20px;
            background: #f2f2f2;
            border: 1px solid transparent;
            border-radius: 4px;      
        
            #loginHeader{
                font-family: 'Merriweather',  serif;
                font-style: italic;
                font-size: 24px;
                text-align: center;
                margin-bottom: 30px;
            }
            
            .inputSection{
                margin: 15px 0; 
                input{
                    display: block
                    background: #fff;
                    border-radius: 5px;
                    padding: 8px 15px;
                    color: #999;
                    border: none;
                    width: 100%;
                    height: auto;
                    outline: 0;
                    &:focus{
                        outline: 0;
                    }
                }
                label{
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #333;
                    display: block;
                    font-size: ${site_config.fontSize.small};
                    margin-bottom: 4px;
                    letter-spacing: 1px;
                }
                
                button.authButton{
                    width: 230px;
                    display: block;
                    background: #36a2d1;
                    height: auto;
                    border: 1px solid transparent;
                    border-radius: 4px;
                    text-align: center;
                    padding: 6px 40px;
                    margin: 0 auto;
                    color: #fff;
                    font-family: Cormorant, sans-serif;
                    font-size: ${site_config.fontSize.medium};
                    cursor: pointer;
                    transition: background-color 300ms ease;
                    
                    &:hover{
                        background: #2784ac;
                    }        
                }
                
                &.btnSection{
                    margin-top: 30px;
                }
            }
            
            .loginLinks{
                width: 100%;
                text-align: center;
                font-size: 17px;
                font-family: Cormorant, sans-serif;
                font-weight: 600;
                font-style: italic;
                margin: 8px 0;
                
                
                a{
                    color: #111;
                }            
            }
        }
    }
`


class LoginComponent extends Component {

  render() {

    const {context} = this.props;
    const {isLoggingIn, loginError} = context.state;


    return (
      <div className="loginWrapper">
        <h1 id="loginHeader">Welcome!</h1>
        <form onSubmit={context.login}>
          <div className="inputSection">
            <label>Email:</label>
            <input key={'email'} value={context.state.email} name={'email'} onChange={context.changeInput}/>
          </div>
          <div className="inputSection">
            <label>Password:</label>
            <input type={'password'} key={'password'} value={context.state.password} name={'password'}
                   onChange={context.changeInput}/>
          </div>
          {loginError ?
            <div className="inputSection" style={{color: '#DD0000'}}>Username or password is wrong</div> : null}
          <div className="inputSection btnSection">
            <Button loading={isLoggingIn} htmlType={'submit'} className={'authButton'}>Log In</Button>
          </div>
        </form>
        <div className={'loginLinks'}>
          <Link to={COURSE_ROUTES.PURCHASE_COURSE}>Register</Link><br/>
          <a onClick={() => context.setState({currentPage: 'reset'})}>Forgot your password?</a>
        </div>
        {/*<div className={'loginLinks'}>*/}
        {/*  <Link to={{*/}
        {/*    pathname: SHARED_ROUTES.REGISTER,*/}
        {/*    state: {*/}
        {/*      redirect: context.props.location.pathname,*/}
        {/*    }*/}
        {/*  }}>Create an account</Link>*/}
        {/*</div>*/}
      </div>
    )
  }
}


class ResetPasswordComponent extends Component {
  render() {
    const {context} = this.props;
    const {isLoggingIn} = context.state;
    return (
      <div className="loginWrapper">
        <div style={{marginBottom: 20}}>
          Please enter your email. You will receive an email containing information on how to create a
          new password.
        </div>
        <form onSubmit={context.resetPassword}>
          <div className="inputSection">
            <label>Email:</label>
            <input key={'email'} value={context.state.email} name={'email'} onChange={context.changeInput}/>
          </div>
          <div className="inputSection btnSection">
            <Button htmlType={'submit'} loading={isLoggingIn} className={'authButton'}>Reset Password</Button>
          </div>
        </form>
        <div className={'loginLinks'}>
          <a onClick={() => context.setState({currentPage: 'login'})}>Back to Log In</a>
        </div>
      </div>
    );
  }
}

class ResetPasswordFailedComponent extends Component{
  render(){
    const {context} = this.props;
    return (
      <div className="loginWrapper">
        <div style={{marginBottom: 20}}>
          The email address you entered does not exist in our database.
        </div>
        <div className={'loginLinks'}>
          <a onClick={() => context.setState({currentPage: 'reset'})}>Use another email</a>
        </div>
      </div>
    )
  }
}

class ResetPasswordSuccessComponent extends Component{
  render(){
    const {context} = this.props;
    return (
      <div className="loginWrapper">
        <div style={{marginBottom: 20}}>
          You will receive an email with a link to reset your password.
        </div>
        <div className={'loginLinks'}>
          <a onClick={() => context.setState({currentPage: 'login'})}>Back to Log In</a>
        </div>
      </div>
    )
  }
}

class LoginPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLoggingIn: false,
      loginError: false,
      currentPage: 'login',
      redirect: false,
    }
  }

  changeInput = e => {
    e.preventDefault();
    const {name, value} = e.target;

    this.setState({
      [name]: value
    });
  }

  resetPassword = e => {
    e.preventDefault();
    const {email, isLoggingIn} = this.state;

    if(isLoggingIn){
      return;
    }
    this.setState({
      isLoggingIn: true,
    })

    BasicRequest.post(API.RESET_PASSWORD_TOKEN, {
      email
    })
      .then(({data}) => {
        console.log(data)
        if(data.success === true){
          this.setState({
            currentPage: 'resetsuccess',
          })
        }
        else{
          this.setState({
            currentPage: 'resetfailed',
          })
        }
      })
      .finally(() => this.setState({isLoggingIn: false,}))
  }

  login = e => {
    e.preventDefault();

    const {forceLogin} = this.props;
    const {email, password, isLoggingIn} = this.state;

    if(isLoggingIn){
      return;
    }

    this.setState({
      isLoggingIn: true,
      loginError: false,
    });

    BasicRequest.post(API.LOGIN, {
      email: email,
      password: password,
    })
      .then(({data, status}) => {

        if (status === 200) {
          const {user, accessToken} = data;
          forceLogin(accessToken, user);
        } else {
          this.setState({
            loginError: true,
          })
        }
      })
      .catch(err => {
        this.setState({
          loginError: true,
        })
      })
      .finally(() => {
        this.setState({
          isLoggingIn: false,
        });
      })

  }

  render() {

    const {isLoggedIn} = this.props;

    const {currentPage} = this.state;

    let redirect = COURSE_ROUTES.INDEX;

    if (this.props.location.state) {
        redirect = this.props.location.state.redirect || SHARED_ROUTES.PROFILE.INDEX;
    }

    return (
      !isLoggedIn ?
      <LoginWrapper>
        <div className="alignContainer">
          {
            currentPage === 'login' && <LoginComponent context={this}/>
          }
          {
            currentPage === 'reset' && <ResetPasswordComponent context={this}/>
          }
          {
            currentPage === 'resetfailed' && <ResetPasswordFailedComponent context={this}/>
          }
          {
            currentPage === 'resetsuccess' && <ResetPasswordSuccessComponent context={this}/>
          }
          {/*<Switch>*/}
          {/*    <Route exact path={SHARED_ROUTES.LOGIN} render={() => <LoginComponent context={this}/>}/>*/}
          {/*    <Route exact path={SHARED_ROUTES.RESET_PASSWORD}*/}
          {/*           render={() => <ResetPasswordComponent context={this}/>}/>*/}
          {/*</Switch>*/}
        </div>
      </LoginWrapper>
        : <Redirect to={redirect}/>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.Auth.get('accessToken') !== null
});

export default connect(mapStateToProps, {forceLogin})(LoginPage);
