import React, {Component, Fragment} from 'react';
import WindowActions from "../../redux/window/actions";
import {connect} from "react-redux";
const {resize} = WindowActions;

class ResponsiveProvider extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        if(window && window.hasOwnProperty('innerWidth')){
            this.onResize();

            window.addEventListener('resize', this.onResize);
        }
    }

    onResize = () => {
        const {
            innerWidth,
            innerHeight
        } = window;

        this.props.resize({
            innerWidth: innerWidth,
            innerHeight: innerHeight,
            desktop: innerWidth > 991,
            tablet: innerWidth > 768 && innerWidth < 992,
            mobile: innerWidth < 768,
        })
    }


    render() {
        return this.props.children;
    }
}

export default connect(null, {resize})(ResponsiveProvider);