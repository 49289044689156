

const SHARED_ROUTES = {
    INDEX: '/',
    LOGIN: '/login',
    LOGIN_REDIRECT: '/profile',
    REGISTER: '/register',
    RESET_PASSWORD: '/reset/:token',

    PROFILE: {
        INDEX: '/profile',
        RESERVATIONS: '/profile/reservations',
        COURSES: '/profile/courses',
        WATCHLIST: '/profile/watchlist',
        SETTINGS: '/profile/settings',
    },
}

export default SHARED_ROUTES;
