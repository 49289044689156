import Auth from "./auth/reducer";
import Window from './window/reducer';
import Layout from './layout/reducer';
import CourseNav from './coursenav/reducer';

const reducers = {
    Auth,
    Window,
    Layout,
    CourseNav,
}

export default reducers;
