import {Map}  from 'immutable';
import actions from "./actions";
import {getToken} from "../../lib/shared/utilities/auth";

const initState = new Map({
    accessToken: null,
    user: {
        id: 0,
        first_name: '',
        last_name: '',
        pictureUrl: null,
        email: null,
        is_active: 0,
        access_trust: false,
        access_course: false,
        reservations_count: 0,
        watchlist_count: 0,
        courses: [],
        member_type: 'none',
    },
    isUpdated: false,
    isLoggingIn: false,
    hasCourseAccess: false,

    firstTimeVisit: false,
    survey: null,

});

export default function authReducer(
    state = initState.merge(getToken()),
    action
){
    switch(action.type){
        case actions.LOGIN_REQUEST:
            return state.set('isLoggingIn', true);

        case actions.LOGIN_SUCCESS:
            return state.set('isLoggingIn', false)
                .set('accessToken', action.token)
                .set('user', action.user)
                .set('firstTimeVisit', action.firstTimeVisit)
                .set('isUpdated', true)
                .set('survey', action.survey);

        case actions.LOGIN_FAILURE:
            return state.set('isLoggingIn', false);

        case actions.LOGOUT:
            return initState;

        case actions.UPDATE_USER:
            return state.set('user', action.user)
                .set('isUpdated', true);

        case actions.SET_COURSE_ACCESS:
            return state.set('hasCourseAccess', action.access)

        case actions.REMOVE_FIRST_TIME_OVERLAY:
            return state.set('firstTimeVisit', false)

        case actions.SET_SURVEY:
            return state.set('survey', action.survey)
        default:
            return state;
    }
}
