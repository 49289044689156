import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import reducers from './reducers';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas";
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {checkAuth} from "../api/requestMiddlewares";


const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const middlewares = [checkAuth, thunk, sagaMiddleware, routeMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history),
    }),
    composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);

export {store, history}
