import {Map} from 'immutable'
import actions from './actions'

const initState = new Map({
    currentItem: '',
    searchOpen: false,
    mobileNavOpen: false,
    currentResource: 1,
    currentAnswer: 0,
    currentSupport: 0,
    modules: [],
    lessons: [],

    showUser: false,
    userToShow: {},
    members: [],
    membersCurrentPage: 1,

    isTourRunning: false,

    liveSessionRunning: false,

    showEnrollPrompt: false,
})

export default function(state = initState, action){
    switch(action.type){
        case actions.SET_CURRENT_ITEM:
            return state.set('currentItem', action.current_item);

        case actions.CLOSE_SEARCH:
            return state.set('searchOpen', false);

        case actions.OPEN_SEARCH:
            return state.set('searchOpen', true);

        case actions.OPEN_COURSE_MOBILE_NAV:
            return state.set('mobileNavOpen', true);

        case actions.CLOSE_COURSE_MOBILE_NAV:
            return state.set('mobileNavOpen', false);

        case actions.SET_MODULES:
            return state.set('modules', action.modules);

        case actions.SET_LESSONS:
            return state.set('lessons', action.lessons);

        case actions.OPEN_MEMBER:
            return state.set('userToShow', action.user)
                        .set('showUser', true);

        case actions.CLOSE_MEMBER:
            return state.set('showUser', false)
                        .set('userToShow', null);

        case actions.UPDATE_MEMBERS:
            return state.set('members', action.members)
              .set('membersCurrentPage', action.currentPage);


        case actions.CLEAR_MEMBERS:
            return state.set('members', [])
              .set('membersCurrentPage', 1);

        case actions.START_TOUR:
            return state.set('isTourRunning', true);

        case actions.END_TOUR:
            return state.set('isTourRunning', false);

        case actions.LIVE_SESSION_RUNNING:
            return state.set('liveSessionRunning', true);

        case actions.LIVE_SESSION_NOT_RUNNING:
            return state.set('liveSessionRunning', false);

        case actions.ENROLL_PROMPT:
            return state.set('showEnrollPrompt', action.show);

        default:
            return state;
    }
}
